import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
export default function Square() {
  return <button className="square">X</button>;
}

/* export default function Profile() {
  return (
    <>
      <h1>ABI</h1>
      <img
        src="image.jpg"
        alt='ABI'
        style={{
          width: "1000",
          height:"600"
        }}
      />
    </>
  );
} */
